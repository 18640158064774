<template>
  <div class="sidebar__item">
    <div class="sidebar__item--title">      
      <h3> {{ $t('products.search') }}  </h3>
      <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14 5L7.5 12L1 5" stroke="#1D1929" stroke-linecap="square"/>
      </svg>
    </div>  
                         
    <div id="myContent" class="sidebar__item--content" v-if="!isReadOnlyForm" >  
      <div class="sidebar__item-form">
        <label class="sidebar__item-form--label">{{ $t('products.whereYouWantToGo') }}
          <b-button variant="primary"  class="btn-blue" size="sm" @click="setIsReadOnly(true)">
            <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6.5 2.4987L6.14647 2.14512L5.79284 2.4987L6.14647 2.85228L6.5 2.4987ZM7.5 1.99878H7V2.99878H7.5V1.99878ZM2 8.49487V7.99487H1V8.49487H2ZM8.14547 0.14642L6.14647 2.14512L6.85353 2.85228L8.85253 0.85358L8.14547 0.14642ZM6.14647 2.85228L8.14547 4.85097L8.85253 4.14381L6.85353 2.14512L6.14647 2.85228ZM7.5 2.99878C10.5372 2.99878 13 5.46048 13 8.49487H14C14 4.90758 11.0888 1.99878 7.5 1.99878V2.99878ZM13 8.49487C13 11.5293 10.5372 13.991 7.5 13.991V14.991C11.0888 14.991 14 12.0822 14 8.49487H13ZM7.5 13.991C4.46283 13.991 2 11.5293 2 8.49487H1C1 12.0822 3.91117 14.991 7.5 14.991V13.991Z" fill="#ffffff"/>
            </svg>

          </b-button>
        </label>

        <div class="sidebar__item-form--input">                  
          <v-select                                                                                                  
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="tourDestinations"
            class="select-destinations"
            placeholder="Select destination"
            @input="setDestinationQueryParams"
            label="name"  
            v-model="myDestination"
            :clearable="false"
          >
            <template #selected-option="{ name }">
              <span class="text-truncate overflow-hidden">
                {{ name }}
              </span>
            </template>
          </v-select> 
        </div>
      </div>

      <div class="sidebar__item-form">
        <label class="sidebar__item-form--label"> {{ $t('products.date') }} </label>
        <div class="sidebar__item-form--input">
          <flat-pickr              
            class="form-control mb-1"
            placeholder="When..."              
            v-model="queryParams.date"
            @input="setDateQueryParams"    
            :config="dateConfig"   
          />
        </div>
      </div>
      <div class="sidebar__item-form">
        <div class="item__total-guests">                
          <span>No. Persons</span>                                                
          <button class="item__btn-guests" type="button" :disabled="true" > {{totalGuest}} </button>
        </div>  
        <div class="guests-pax">                
          <span  class="pax-type"> {{ $t('products.adultsSearch') }}</span>                  
          <div class="pax-qty">
              <button class="btn-qty" type="button" :disabled="queryParams.adults == 1"  @click="setPaxAdults('-')">
                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 7.5H14" stroke="#34303E"/>
                </svg>
              </button>
              <span> {{ queryParams.adults }} </span>
              <button class="btn-qty" type="button" @click="setPaxAdults('+')" :disabled="queryParams.adults === maxAdultsPax">
                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7.5 1V14M1 7.5H14" stroke="#34303E"/>
                </svg>
              </button>
          </div>
        </div>      
        <div class="guests-pax">                
          <span  class="pax-type"> {{ $t('products.childrenSearch') }}</span>                  
          <div class="pax-qty">
            <button class="btn-qty" type="button" :disabled="queryParams.children == 0" @click="setPaxChildren('-')">
              <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 7.5H14" stroke="#34303E"/>
                </svg>
            </button>
            <span> {{queryParams.children}} </span>
            <button class="btn-qty" type="button"  @click="setPaxChildren('+')" :disabled="queryParams.children === maxChildrenPax" >
              <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7.5 1V14M1 7.5H14" stroke="#34303E"/>
                </svg>
            </button>
          </div>
        </div>           
      </div>
      <div class="sidebar__item-form">
        <div class="btn-search">
          <button class="button button--flex" @click="searchData" :disabled="isLoadingProducts">                        
            {{ $t('products.exploreNow') }} 
          </button>
        </div>                                                             
      </div>
    </div>

    <div id="myContent" class="sidebar__item--content " v-else>    
      <div class="sidebar__item-form">
        <label class="sidebar__item-form--label">{{ $t('products.whereYouWantToGo') }}
          <b-button variant="primary"  class="btn-blue" size="sm" @click="setIsReadOnly(false)">
            <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0.5 9.5L0.146447 9.14645L0 9.29289V9.5H0.5ZM9.5 0.5L9.85355 0.146447C9.65829 -0.0488155 9.34171 -0.0488155 9.14645 0.146447L9.5 0.5ZM14.5 5.5L14.8536 5.85355C15.0488 5.65829 15.0488 5.34171 14.8536 5.14645L14.5 5.5ZM5.5 14.5V15H5.70711L5.85355 14.8536L5.5 14.5ZM0.5 14.5H0C0 14.7761 0.223858 15 0.5 15L0.5 14.5ZM0.853553 9.85355L9.85355 0.853553L9.14645 0.146447L0.146447 9.14645L0.853553 9.85355ZM9.14645 0.853553L14.1464 5.85355L14.8536 5.14645L9.85355 0.146447L9.14645 0.853553ZM14.1464 5.14645L5.14645 14.1464L5.85355 14.8536L14.8536 5.85355L14.1464 5.14645ZM5.5 14H0.5V15H5.5V14ZM1 14.5V9.5H0V14.5H1ZM6.14645 3.85355L11.1464 8.85355L11.8536 8.14645L6.85355 3.14645L6.14645 3.85355ZM8 15H15V14H8V15Z" fill="#ffffff"/>
            </svg> 
          </b-button>
        </label>
        <div class="sidebar__item-form--input">                  
          {{ destination ? destination.name: '' }}
        </div>

      </div>   
      <div class="sidebar__item-form">
        <label class="sidebar__item-form--label"> {{ $t('products.date') }} </label>
        <div class="sidebar__item-form--input">
          {{ dateFormatted }}
          <!-- desde el mixin market -->
        </div>
      </div>
      <div class="sidebar__item-form">
        <div class="item__total-guests">        
          <span>{{ $t('products.noPersons') }}</span>                                                
          <button class="item__btn-guests" type="button" :disabled="true" > {{totalGuest}} </button>
        </div>  
        <div class="guests-pax">                
          <span  class="pax-type"> {{ $t('products.adultsSearch') }}</span>                                          
          <span> {{ queryParams.adults }} </span>                      
        </div>      
        <div class="guests-pax">                
          <span  class="pax-type"> {{ $t('products.childrenSearch') }}</span>                                      
          <span> {{queryParams.children}} </span>                   
        </div>           
      </div>    
    </div>
  </div>
</template>

<script>
  import { mapState, mapActions,  mapMutations, mapGetters } from 'vuex'
  import { market } from '@/modules/shop/mixins/market'
  import { dataLayer } from '@/modules/shop/mixins/dataLayer'
  import flatPickr from 'vue-flatpickr-component'
  import vSelect from 'vue-select'
  
  import { Spanish } from 'flatpickr/dist/l10n/es.js'
  import { english } from 'flatpickr/dist/l10n/default.js'

  import { currentDateAddOneDay, removeNullValuesFromAnObject, toJson } from '@/helpers/helpers'
  export default {     
    mixins: [market, dataLayer], 
    components: {             
      vSelect,
      flatPickr                 
    }, 
    mounted(){

       this.setLoadingProducts( false )  
       this.searchData()
    },

    data(){
      return{
        isReadOnlyForm: true,
        myDestination: this.$store.state.start?.selectedDestination || null,  
      }
    },
    computed: {
      ...mapState('auth',['queryParams','lang']),               
      ...mapState('start',['categories','tourDestinations','selectedCategory','selectedDestination']),
      ...mapState('products',['isLoadingProducts','products']),                                    
      ...mapGetters('products',['filteredProducts']), 
      dateConfig(){
        // locale: this.getLocale !== 'en' ? require(`flatpickr/dist/l10n/es.js`).default['es'] : 'en'
        const langLocale = this.lang
        const langs = { en: english, es: Spanish }

        return {
          // mode: "range",
          minDate: currentDateAddOneDay(),
          maxDate: '',        
          altInput: true,
          altFormat: "l, F j, Y",
          locale: langs[langLocale],
          // dateFormat: "Y-m-d",
        }
      },
      totalGuest()  {
        return parseFloat(this.queryParams?.adults) + parseFloat(this.queryParams?.children)
      },
      maxAdultsPax()  {
        return process.env.VUE_APP_ADULTS_MAX_PAX ? parseFloat(process.env.VUE_APP_ADULTS_MAX_PAX) : false
      },
      maxChildrenPax()  {
        return process.env.VUE_APP_ADULTS_MAX_PAX ? parseFloat(process.env.VUE_APP_CHILDREN_MAX_PAX) : false
      },
   
      destination(){
        if(this.selectedDestination){
          return this.selectedDestination
        }
        return null
      }
    },
    methods:{
      ...mapActions('products',['fetchProductsBySubCategory','fetchProductsByCategories', 'fetchBestSellerProducts','fetchProductsRate']),    
      ...mapMutations('products',['setProducts','setLoadingProducts','setSortBy']),                     
      ...mapMutations('start',['setCategories','setTourDestinations','setSelectedDestination']),
      ...mapMutations('auth',['setDateInQueryParams','setCategoryQueryParam', 'setSubCategoryQueryParam','setDestinationInQueryParams','setNumberChildren','setNumberAdults']),      
      ...mapMutations('shop',['updatePaxInCart']),
      setDestinationQueryParams(destination){
        if(destination){
          this.setDestinationInQueryParams(destination.id)  
          this.setSelectedDestination(destination)
          this.calculateBreakdownInCart() // desde el mixin market
          this.$router.replace({ query: {...this.queryParams} }).catch(() => {})            
        }
        if(!destination){
          this.setDestinationInQueryParams(null) 
          this.setSelectedDestination(null)      
          const newQueryParams = removeNullValuesFromAnObject(this.queryParams)       
          this.$router.replace({ query: {...newQueryParams} }).catch(() => {})            
          this.setQueryParams()
        }
      },
      setDateQueryParams(date){
        this.setDateInQueryParams(date)  
        this.calculateBreakdownInCart() // desde el mixin market
          
        this.$router.replace({ query: {...this.queryParams} }).catch(() => {})            
      },
      setPaxChildren(operator){
        const children = operator === '-' ? parseFloat( this.queryParams.children ) - 1 : parseFloat( this.queryParams.children) + 1
        this.setNumberChildren(children)
        this.updatePaxInCart({ adults: parseInt(this.queryParams.adults), children, type: 'childrens' } )
        this.calculateBreakdownInCart() // desde el mixin market
        this.$router.replace({ query: {...this.queryParams} }).catch(() => {})            
      },
      setPaxAdults(operator){
        const adults = operator === '-' ? parseFloat( this.queryParams.adults ) - 1 : parseFloat( this.queryParams.adults) + 1            
        this.setNumberAdults(adults)
        this.updatePaxInCart({ adults, children: parseInt(this.queryParams.children), type: 'adults' })
        this.calculateBreakdownInCart() // desde el mixin market
        this.$router.replace({ query: {...this.queryParams} }).catch(() => {})
      },
      async searchData(){
        const payload = toJson( this.payloadSearch() )         
        let products = [] 
        this.setProducts([])
        this.setLoadingProducts( true)
       
      
          products = await this.fetchProductsRate( payload )               
     
        this.setProducts(products)
        this.setLoadingProducts( false)   
        this.$emit('close-search-collapse-from-no-read')      
      },
      payloadSearch(){
        let payload = {}

          const existSubCategory = this.selectedCategory.idSubCategory !== null && this.selectedCategory.idCategory === null
          const valorCategory = this.selectedCategory ? this.selectedCategory.idCategory : null
             
          payload = {            
            adults: parseInt(this.queryParams.adults),
            children: parseInt(this.queryParams.children),
            date: this.queryParams.date,                        
            idCategory : null,
            idSubCategory : existSubCategory ? this.selectedCategory.idSubCategory : null,
            iddest: this.queryParams.destination ? parseInt(this.queryParams.destination) : null
          }        
        
        return payload
      },      
      setIsReadOnly(boolean){        
        this.isReadOnlyForm = boolean 
        /*Si form no es de modo lectura modifico el css inline del elemento con id  myContent a un valor mas grande que el que tiene previamente 
         en modo read-only el form llega a los 276px, al hacer el swich a modo no-read-only ese heigh de 276 se queda corto, porque requiere minimo
         un heigh de 476px, por ende le pongo un valor a maxHeight que supere ese valor
         */
        if(!boolean){
          const myContent = document.querySelector('#myContent')
          if (myContent) {
            myContent.style.maxHeight = 500 + "px"
          }          
        }
      }
    },
    watch: {            
    selectedDestination: { // '$route.query.idItem'
      immediate: true,
      handler() {
        if( this.selectedDestination ) {
          this.myDestination = this.selectedDestination
        }                       
      }
    }
  },        
  }
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/filter-search.scss";  
</style>