<template>
  <div class="sidebar__item">
    <b-row>
      <b-col md="9">
        <b-skeleton animation="fade" width="50%" height="25px"></b-skeleton>
      </b-col>
      <b-col md="3">
          <b-skeleton animation="fade" width="100%" height="25px"></b-skeleton>
      </b-col>
    </b-row> 
    <b-row class="mt-1">
      <b-col md="12">
          <b-skeleton animation="fade" width="50%" height="18px"></b-skeleton>
          <b-skeleton animation="fade" width="55%" height="12px" class="ml-1"></b-skeleton>
          <b-skeleton animation="fade" width="55%" height="12px" class="ml-1"></b-skeleton>
          <b-skeleton animation="fade" width="55%" height="12px" class="ml-1"></b-skeleton>
          <b-skeleton animation="fade" width="55%" height="12px" class="ml-1"></b-skeleton>

      </b-col>
      <b-col md="12">
          <b-skeleton animation="fade" width="50%" height="18px"></b-skeleton>
          <b-skeleton animation="fade" width="55%" height="12px" class="ml-1"></b-skeleton>
          <b-skeleton animation="fade" width="55%" height="12px" class="ml-1"></b-skeleton>
          <b-skeleton animation="fade" width="55%" height="12px" class="ml-1"></b-skeleton>
          <b-skeleton animation="fade" width="55%" height="12px" class="ml-1"></b-skeleton>

      </b-col>

      <b-col md="12">
          <b-skeleton animation="fade" width="50%" height="18px"></b-skeleton>
          <b-skeleton animation="fade" width="55%" height="12px" class="ml-1"></b-skeleton>
          <b-skeleton animation="fade" width="55%" height="12px" class="ml-1"></b-skeleton>
          <b-skeleton animation="fade" width="55%" height="12px" class="ml-1"></b-skeleton>
          <b-skeleton animation="fade" width="55%" height="12px" class="ml-1"></b-skeleton>
          <b-skeleton animation="fade" width="55%" height="12px" class="ml-1"></b-skeleton>
          <b-skeleton animation="fade" width="55%" height="12px" class="ml-1"></b-skeleton>
          <b-skeleton animation="fade" width="55%" height="12px" class="ml-1"></b-skeleton>


      </b-col>
    </b-row>
  </div>
</template>

<script>
    export default {
        
    }
</script>

<style scoped>


.sidebar__item{
    background-color: #ffffff;   
    padding: 2rem 2rem 1rem 2rem;     
    border-radius: 6px;
    margin-bottom: 1rem;
    box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);      
  }

</style>