<template>
  <div>
    <Navigation/>    
      <div class="wrapper-results grid">     
        <aside class="sidebar">
          <div class="sidebar__container">
        
            <SkeletonSearch v-if="isLoadingSidebar" />
            <FilterSearch @close-search-collapse="closeCollapse" @set-is-loading-sidebar="setIsLoadingSidebar" v-else/>

            <SkeletonCategories v-if="isLoadingSidebar" />
            <FilterCategory @set-to-page-one="setCurrentPage" @collapse-categories="collapseCategories" v-else/>

          </div>     

        </aside>

        <div class="products">
          
          <main class="grid main" v-if="!isLoadingProducts && filteredProducts.length > 0 ">                          
            <Product class="card__product" v-for="(product, index) in productos" :key="index" :product="product"/>                  
          </main>

          <main class="grid main isloading" v-if="isLoadingProducts">                       
            <SkeletonProduct class="card__product isloading" v-for="item in numSkeletonProducts" :key="item.index"/>
          </main>
          
          <div class="text-center mt-2" v-if="!isLoadingProducts && filteredProducts.length > 0" >
            <ProductsPagination :totalRows="filteredProducts.length" :filters="filters" v-if="filteredProducts.length > 8"/>
          </div> 

          <b-alert show variant="warning"  class="mt-1 p-3" v-if="!isLoadingProducts && filteredProducts.length == 0">
            <div class="alert-body text-center"> <h2>No products found</h2></div>
          </b-alert>
        </div>

      </div>  
    <Footer/>
  </div>
</template>
<script>
import { mapState, mapActions,  mapMutations, mapGetters } from 'vuex'
import Navigation from '@/modules/shop/components/experiences/Navigation'
import FilterSearch from '@/modules/shop/components/experiences/FilterSearch'
import FilterCategory from '@/modules/shop/components/experiences/FilterCategory'

import Product from '@/modules/shop/components/experiences/Product'
import ProductsPagination from '@/modules/shop/components/experiences/ProductsPagination'
import Footer from '@/modules/shop/components/experiences/Footer'
// skeletons
import SkeletonProduct from '@/modules/shop/components/skeletons/SkeletonProduct'
import SkeletonSidebarTitle from '@/modules/shop/components/skeletons/SkeletonSidebarTitle'
import SkeletonSearch from '@/modules/shop/components/skeletons/SkeletonSearch'
import SkeletonCategories from '@/modules/shop/components/skeletons/SkeletonCategories'

import { collapsibleFilters } from "@/assets/js/collapsible"
import { myDropdown } from "@/assets/js/dropdown"

import { market } from '@/modules/shop/mixins/market'
import { dataLayer } from '@/modules/shop/mixins/dataLayer'
import { filters } from "@/data/data"

export default { 
  mixins: [ market, dataLayer],    

  components: {     
    Navigation,   
    Footer, 
    
    FilterSearch,
    FilterCategory,
    Product,
    ProductsPagination,
    SkeletonProduct,
    SkeletonSidebarTitle,
    SkeletonSearch,
    SkeletonCategories
  }, 
  
  async created(){
    document.title = `${process.env.VUE_APP_NAME } | Results`      
    await this.init()  
    collapsibleFilters()
    myDropdown()
    this.openCollapsesIfGreaterThanOrEqualTo540Px()      
  }, 

  data(){
      return {
        isLoadingSidebar: false,                                        
        filters,        
        itemView:'grid-view',
            
        imgUrl: process.env.VUE_APP_BASE_URL_SERVER,   
        numSkeletonProducts:[1,2,3,4,5,6,7,8],
        linkCss: 'https://necolas.github.io/normalize.css/8.0.1/normalize.css'            
      }
    },
  computed:{                               
    ...mapState('auth',['queryParams','lang']),  
    ...mapState('appConfig',['windowWidth']),                                  
    ...mapState('start',['categories','tourDestinations','selectedCategory','selectedDestination']),
    ...mapState('products',['isLoadingProducts','products']),                                    
    ...mapGetters('products',['filteredProducts']), 
    productos(){
      const items = this.filteredProducts
      return items.slice(
        (this.filters.currentPage - 1) * this.filters.perPage,
        this.filters.currentPage * this.filters.perPage
      )
    },             
  },
  methods:{      
    ...mapActions('start',['fetchCategories','fetchDestinationsTours']),         
    ...mapActions('products',['fetchProductsBySubCategory','fetchProductsByCategories', 'fetchBestSellerProducts']),    
    ...mapMutations('products',['setProducts','setLoadingProducts','setSortBy']),                     
    ...mapMutations('start',['setCategories','setTourDestinations','setSelectedDestination']),
    ...mapMutations('shop',['clearCart','setOrderCreated']),    
                
    async init(){
      this.clearStorage()
      this.setLoadingProducts( true ) 
      this.setQueryParams() //desde el mixin market
      this.overWriteTokenAxios() // desde el mixin market
      this.setQueryStringInUrl() // desde el mixin market
      await this.getDestinations()
      await this.getCategories()         
      await this.getProducts() 
      this.setDestination()                  
    },
    async getDestinations(){        
      const tourDestinations = await this.fetchDestinationsTours()        
      tourDestinations.unshift({id:0, name: this.$t("products.allDestinations") })

      this.setTourDestinations(tourDestinations)        
    },
    async getCategories(){  
      this.isLoadingSidebar = true      
      const categories = await this.fetchCategories()
      this.setCategories(categories)  
      this.isLoadingSidebar = false
    },
    async getProducts(){    
    
                    
       
      // del data layer
      this.pushImpressions()
    },     

    categoriesAreEmpty(){  
      const { categoryServices, hotelServices, onlyCategories, withSubCategories } = this.categories   
      if( !withSubCategories.length || !onlyCategories.length || !hotelServices.length || !categoryServices.length ){
        return true
      }
      return false
    },  
    setDestination(){   
      this.setSelectedDestination( null )
      const idDestination = this.queryParams?.destination ? parseInt(this.queryParams.destination) : null
      if(idDestination){
        const destination = this.tourDestinations.find( destination => destination.id == idDestination )
        if(destination) this.setSelectedDestination(destination) //si se encuenta algo, muta el state
      }
      if(!idDestination){
        this.setSelectedDestination( { id:0, name: this.$t("products.allDestinations")  })
      }      
    },
    setCurrentPage() {
      this.filters.currentPage = 1
    },
    closeCollapse(){
      document.getElementsByClassName("sidebar__item--title")[1].click() // simulo el click al collapse buscador                
    },
    setIsLoadingSidebar(boolean){
      this.isLoadingSidebar = boolean        
    },
    openCollapsesIfGreaterThanOrEqualTo540Px(){
      const mediaQuery = window.matchMedia("(min-width: 540px)")
      const { matches } = mediaQuery
      if(matches){ //si matches es true, abro el collapsible de categorías
        document.getElementsByClassName("sidebar__item--title")[0].click() // simulo el click al collapse search       
        document.getElementsByClassName("sidebar__item--title")[1].click() // simulo el click al collapse categorias       
      }
    },
    collapseCategories(){
      const windowWidth = this.windowWidth    
      if(windowWidth <= 539 ){
        document.getElementsByClassName("sidebar__item--title")[1].click() // simulo el click al collapse categorias       
      }        
    },      
  },
  watch:{    
    async lang(){      
      this.overWriteTokenAxios()
      await this.init()  
      collapsibleFilters()
      this.openCollapsesIfGreaterThanOrEqualTo540Px() 
      this.clearCart() //si hay algo en cart, lo limpio
      this.setOrderCreated(null) //si ya hay una orden creada, se elimina
    }
  }
}

</script>

<style lang="scss" >
  @import "@core/scss/vue/libs/vue-select.scss";
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
  .custom-radio.custom-control {
    padding-left: 2rem;
    margin-bottom: 1rem;
  }  
</style>

<style lang="scss" scoped>
  @import "@/assets/scss/results.scss";  
</style>

