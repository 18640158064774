<template>
  <div>
    <img class="card__img" :src="imgProduct + product.coverImage" :alt="product.name" @error="imgAlternativo"  @click="goToReserve(product)">
    <div class="card__price"> {{ $t('products.startingAt') }}<span> ${{product.detail[0].saleprice}} USD</span> </div>
    <div class="card__info">
        <h2 class="card__title" style="cursor: pointer" @click="goToReserve(product)">{{product.name}}</h2>
        <div class="card__short-description" > {{ productDescription.replace(/<\/?[^>]+>/gi, ' ') }}</div>
        <a class="card__view-more" @click="goToReserve(product)">
          {{ $t('products.showMore') }}
        </a>  
        <div class="footer-card">            
            <div class="card__details" v-if="product.isLegacy" >
              <div class="card__details--pax" v-if="detailAdult && queryParams.adults > 0">
                <span> {{ $tc('products.adults', queryParams.adults, { count: queryParams.adults } ) }} </span>
                <span>${{ (detailAdult.saleprice * queryParams.adults).toFixed(2) }}</span>
              </div>
              <div class="card__details--pax" v-if="detailChild && queryParams.children > 0">
                <span> {{ $tc('products.children', queryParams.children, { count: queryParams.children } ) }} </span>
                <span>${{ (detailChild.saleprice * queryParams.children).toFixed(2) }}</span>
              </div>
              <div class="card__details--pax ">
                <b class="total-product">{{ $t('products.totalPrice') }}:</b>
                <b class="total-product">${{ totalBreakdown(product) }}</b>
              </div>
            </div>

			<div class="card__details" v-if="!product.isLegacy">
				<div class="card__details--pax"  v-if="typeItem.isAdult" >
					<span> {{ $tc('products.adults', typeItem.isAdult.num, { count: typeItem.isAdult.num } ) }} </span>
					<span>${{ (typeItem.isAdult.saleprice * typeItem.isAdult.num ).toFixed(2) }}</span>
				</div>
				<div class="card__details--pax"   v-if="typeItem.isChild && typeItem.isChild.num > 0">
					<span> {{ $tc('products.children', typeItem.isChild.num, { count: typeItem.isChild.num } ) }} </span>
					<span>${{ (typeItem.isChild.saleprice * typeItem.isChild.num ).toFixed(2) }}</span>
				</div>
				<div class="card__details--pax" v-if="typeItem.isGroup">
					<span> Group: {{  typeItem.isGroup.num,  }} </span>
					<span>${{ (typeItem.isGroup.total ).toFixed(2) }}</span>
				</div>
				<div class="card__details--pax ">
					<b class="total-product">{{ $t('products.totalPrice') }}:</b>
					<b class="total-product">$ {{ roundedTotal }} </b>
				</div>
			</div>
            
            <b-button variant="primary" @click="goToCheckout(product)" class="btn-blue">{{ $t('products.book') }}</b-button> 
        </div> 
    </div>
  </div>
</template>

<script>
import { mapState, mapActions,  mapMutations } from 'vuex'
import { toDecimal } from '@/helpers/helpers'
import { market } from '@/modules/shop/mixins/market'
import { dataLayer } from '@/modules/shop/mixins/dataLayer'

export default {
  mixins: [market, dataLayer],    
  props:{
      product: {
        type: Object,
        required: true,
      },
  },
  data(){
      return {
          imgUrl: process.env.VUE_APP_BASE_URL_SERVER,    
          imgUrlExperience: process.env.VUE_APP_IMGEXP_SRC_API,            
      }
  },
  computed: {
    ...mapState('auth',['queryParams']),                       
    detailAdult(){
      return this.product.detail.find( d => d.detailDesc?.toLowerCase() == 'adult' || d.detailDesc?.toLowerCase() == 'adulto' )
    },
    detailChild(){
      return this.product.detail.find( d => d.detailDesc?.toLowerCase() == 'child' || d.detailDesc?.toLowerCase() == 'menor' )
    },
	roundedTotal(){
		return Math.round( this.product.total ).toFixed( 2 ) 
	},
	typeItem(){

		let isAdult = null
		let isChild = null
		let isGroup = null

		if( !this.product.isLegacy ){

			isAdult =  this.product.detail.find( d => d.type === 1 )
			isChild =  this.product.detail.find( d => d.type === 2 )
			isGroup =  this.product.detail.find( d => d.type === 3 )
		}

		return { isAdult, isChild, isGroup}
	},
    productDescription(){
      var txt = document.createElement("textarea");
      txt.innerHTML = this.product.description;
      return txt.value;
    },
    imgProduct(){
      let img
    if(this.product.modelType=="rates"){
      img=this.imgUrlExperience
    }else{
      img=this.imgUrl
    }
      return img;
    }
  },
  methods:{
    ...mapActions('shop', ['fetchBlackoutDatesProduct']),
    ...mapMutations('shop',['addProductInCart','clearCart']),    
    ...mapMutations('products',['resetQtyProductDetail','setProductDetail','setOriginSelectedProduct']),
    ...mapMutations('auth',['setDataQueryParams']), 
    goToReserve(item){      
      this.setDataQueryParams({...this.queryParams, idItem: item.id })
      this.setProductDetail(null) //si hay algo en detalle de producto lo limpio   
      this.setOriginSelectedProduct( item )   
      this.clearCart()
      this.$router.push({ name:'item-detail', query: {...this.queryParams} })
    },
    goToCheckout(item){      
      this.setDataQueryParams({...this.queryParams, idItem: item.id })
      this.setProductDetail(null) //si hay algo en detalle de producto lo limpio
      this.clearCart()    
      this.initAddToCart({...item})// desde el mixin market
      this.calculateBreakdownInCart() // desde el mixin market
      this.$router.push({ name:'checkout', query: {...this.queryParams} })      
    },
    totalBreakdown(item){        
        const { detail } = item
        if( detail.length > 0 ){                      
            const totalAdults = this.detailAdult ? parseFloat ( this.detailAdult.saleprice ) * parseFloat(this.queryParams.adults) : 0
            const totalChild = this.detailChild ? parseFloat ( this.detailChild.saleprice ) * parseFloat(this.queryParams.children) : 0            
            return toDecimal( totalAdults + totalChild )            
        }
    },
    imgAlternativo( event ){
      event.target.src = require('@/assets/images/default.jpg')
    },
  }       
}

</script>


<style lang="scss" scoped>
  @import "@/assets/scss/product.scss";  
 
</style>
