export const filters = {
    q: '',
    priceRangeDefined: 'all',
    priceRange: [0, 100],
    categories: [],
    hotel: '',
    category: '',
    service: '',
    ratings: null,
    currentPage: 1,
    perPage: 8,
}
