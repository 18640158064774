import { mapState, mapMutations, mapGetters } from 'vuex'

export const dataLayer = {
  data () {
    return { 
      params: this.$route.query,      
      codesAllowed: [32, 43, 45, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57],      
    }
  }, 
  computed:{    
    ...mapState('products',['products']),    
    ...mapState('shop',['cart']),       
    ...mapState('auth',['queryParams','user', 'isLogged']), 
    ...mapGetters('shop',['isEmptyCart']),
    idItemDetail(){
      return this.$route.query.idItem ? this.$route.query.idItem : null
    }                                
  },
  methods: {                                   
    ...mapMutations('start',['setSelectedCategory','setSelectedDestination']),
    ...mapMutations('auth',['setDataQueryParams']),  
    ...mapMutations('products',['setProductDetail']),
    ...mapMutations('shop',['addProductInCart','clearCart','calculateTotalsItemsCart']),    
    pushImpressions(){   
        let impressions = []
        // const user = store.state.auth.user            
        let products = [...this.products]         
        products.forEach( (item, index) => {
            const obj = {          
                brand: `Brand item`,
                category:item.categoryName,
                date: item.startDate || '',
                hotel: item.hotelName || '',
                id: item.id,
                location: item.locationName || '',
                name: item.name,
                position: index + 1,
                price: item.detail[0].saleprice,
                variant: item.detaildisplay || '' ,
            }        
            impressions.push(obj)
        })  
        
        window.dataLayer?.push({ ecommerce: null }) //nulo primero
        window.dataLayer?.push({currencyCode: 'USD', impressions})
        // console.log(impressions)
    }
        
  },
 
}



